<template>
	<div>
		<b-row>
			<b-col cols="12">
				<b-card>
					<b-card-text>
						<b-row>
							<b-col cols="2">
								<label>类型</label>
								<b-form-select></b-form-select>
							</b-col>
							<b-col cols="2">
								<label>区域</label>
								<b-form-select></b-form-select>
							</b-col>
							<b-col cols="2">
								<label>分项</label>
								<b-form-select></b-form-select>
							</b-col>
							<b-col cols="2">
								<label>时间</label>
								<b-form-select></b-form-select>
							</b-col>
							<b-col class="d-flex align-items-end justify-content-end" cols="4">
								<b-button variant="primary">查询</b-button>
								<b-button class="ml-1" variant="outline-primary">导出</b-button>
							</b-col>
						</b-row>
					</b-card-text>
				</b-card>
			</b-col>
		</b-row>

		<b-row>
			<b-col cols="12">
				<b-card no-body>
					<b-table :items="items" hover responsive striped />
				</b-card>
				<b-pagination v-model="currentPage" :total-rows="rows" align="right" first-number hide-goto-end-buttons last-number next-class="next-item" prev-class="prev-item" />
			</b-col>
		</b-row>
	</div>
</template>

<script>
	import { onMounted } from "@vue/composition-api";
	import { Column, Line, Pie } from "@antv/g2plot";
	import { BPagination, BTable } from "bootstrap-vue";

	export default {
		name: "index",
		components: {
			BTable,
			BPagination,
		},
		data() {
			return {
				currentPage: 1,
				rows: 100,

				items: [
					{
						age: 40,
						first_name: "Dickerson",
						last_name: "Macdonald",
						Occupation: "Job",
					},
					{
						age: 21,
						first_name: "Larsen",
						last_name: "Shaw",
						Occupation: "Job",
					},
					{
						age: 89,
						first_name: "Geneva",
						last_name: "Wilson",
						Occupation: "Bussiness",
					},
					{
						age: 38,
						first_name: "Jami",
						last_name: "Carney",
						Occupation: "Bussiness",
					},
					{
						age: 40,
						first_name: "James",
						last_name: "Thomson",
						Occupation: "Job",
					},
				],
			};
		},
		setup() {
			const initLine = () => {
				fetch("https://gw.alipayobjects.com/os/bmw-prod/55424a73-7cb8-4f79-b60d-3ab627ac5698.json")
					.then((res) => res.json())
					.then((data) => {
						const line = new Line("container1", {
							data,
							xField: "year",
							yField: "value",
							seriesField: "category",
							legend: {
								layout: "horizontal",
								position: "bottom",
							},
							xAxis: {
								type: "time",
							},
							yAxis: {
								label: {
									// 数值格式化为千分位
									formatter: (v) => `${v}`.replace(/\d{1,3}(?=(\d{3})+$)/g, (s) => `${s},`),
								},
							},
						});

						line.render();
					});
			};
			const initColumn = () => {
				const data = [
					{
						name: "London",
						月份: "Jan.",
						月均降雨量: 18.9,
					},
					{
						name: "London",
						月份: "Feb.",
						月均降雨量: 28.8,
					},
					{
						name: "London",
						月份: "Mar.",
						月均降雨量: 39.3,
					},
					{
						name: "London",
						月份: "Apr.",
						月均降雨量: 81.4,
					},
					{
						name: "London",
						月份: "May",
						月均降雨量: 47,
					},
					{
						name: "London",
						月份: "Jun.",
						月均降雨量: 20.3,
					},
					{
						name: "London",
						月份: "Jul.",
						月均降雨量: 24,
					},
					{
						name: "London",
						月份: "Aug.",
						月均降雨量: 35.6,
					},
					{
						name: "Berlin",
						月份: "Jan.",
						月均降雨量: 12.4,
					},
					{
						name: "Berlin",
						月份: "Feb.",
						月均降雨量: 23.2,
					},
					{
						name: "Berlin",
						月份: "Mar.",
						月均降雨量: 34.5,
					},
					{
						name: "Berlin",
						月份: "Apr.",
						月均降雨量: 99.7,
					},
					{
						name: "Berlin",
						月份: "May",
						月均降雨量: 52.6,
					},
					{
						name: "Berlin",
						月份: "Jun.",
						月均降雨量: 35.5,
					},
					{
						name: "Berlin",
						月份: "Jul.",
						月均降雨量: 37.4,
					},
					{
						name: "Berlin",
						月份: "Aug.",
						月均降雨量: 42.4,
					},
				];

				const stackedColumnPlot = new Column("container2", {
					data,
					isGroup: true,
					xField: "月份",
					yField: "月均降雨量",
					seriesField: "name",
					legend: {
						layout: "horizontal",
						position: "bottom",
					},
				});

				stackedColumnPlot.render();
			};
			const initPieS = () => {
				const data = [
					{ type: "分类一", value: 27 },
					{ type: "分类二", value: 25 },
					{ type: "分类三", value: 18 },
					{ type: "分类四", value: 15 },
					{ type: "分类五", value: 10 },
					{ type: "其他", value: 5 },
				];

				const piePlot = new Pie("container3", {
					appendPadding: 10,
					data,
					angleField: "value",
					colorField: "type",
					radius: 0.75,
					label: {
						type: "spider",
						labelHeight: 28,
						content: "{name}\n{percentage}",
					},
					legend: {
						layout: "horizontal",
						position: "bottom",
					},
					interactions: [{ type: "element-selected" }, { type: "element-active" }],
				});

				piePlot.render();
			};
			onMounted(() => {
				// initPieS(), initLine(), initColumn();
			});
		},
	};
</script>

<style lang="scss" scoped></style>
